const myFavourites = {

    // gets from local storage
    getFavourites: () => {
        return JSON.parse(localStorage.getItem("favourites")) || []
    },

    // adds to local storage
    addFavourite: (listingId, listingTitle, listingUrl) => {
        console.log("Favourite added", listingId, listingTitle, listingUrl)
        const favourites = myFavourites.getFavourites()
        favourites.push({
            id: listingId,
            title: listingTitle,
            url: listingUrl
        })
        localStorage.setItem("favourites", JSON.stringify(favourites))
    },
    // removes from local storage
    removeFavourite: (listingId) => {
        console.log("Favourite removed", listingId)
        const favourites = myFavourites.getFavourites()
        const index = favourites.findIndex(favourite => favourite.id === listingId)
        if (index !== -1) {
            favourites.splice(index, 1)
        }
        localStorage.setItem("favourites", JSON.stringify(favourites))
    },

    // determines whether to add or remove from local storage
    toggleFavourite: (listingId, listingTitle, listingUrl) => {
        if (myFavourites.isFavourite(listingId)) {
            myFavourites.removeFavourite(listingId)
        } else {
            myFavourites.addFavourite(listingId, listingTitle, listingUrl)
        }

        // update the favourites list DOM
        myFavourites.updateFavouritesDOM()
    },

    // checks if the listing is in local storage
    isFavourite: (listingId) => {
        const favourites = myFavourites.getFavourites()
        return favourites.some(favourite => favourite.id === listingId)
    },

    // gets the number of favourites
    getFavouriteCount: () => {
        const favourites = myFavourites.getFavourites()
        return favourites.length
    },

    // clears the favourites from local storage
    clearFavourites: () => {
        localStorage.removeItem("favourites")
    },

    // updates the favourites list DOM element
    updateFavouritesDOM: () => {
        const favourites = myFavourites.getFavourites()
        const favouritesList = document.getElementById("myFavsList")
        console.log(favouritesList)
        if (favouritesList) {
            favouritesList.innerHTML = ""
            if (favourites.length > 0) {
                favourites.forEach(favourite => {
                    const favouriteItem = document.createElement("a")
                    favouriteItem.classList.add("favourite-item", "block", "hover:cursor-pointer", "py-2", "border-b", "border-char-30")
                    favouriteItem.innerHTML = favourite.title
                    favouriteItem.href = favourite.url
                    favouritesList.appendChild(favouriteItem)
                })
            } else {
                favouritesList.innerHTML = "<p>You haven't added any favourites yet!</p>"
            }
        }
    }
}

const favButton = document.getElementById("favButton")

if (favButton) {
    const listingId     = favButton.getAttribute("data-listing-id")
    const listingTitle  = favButton.getAttribute("data-listing-title")
    const listingUrl    = favButton.getAttribute("data-listing-url")
    const bgColour      = favButton.getAttribute("data-bg-colour")

    // declare counter elements
    const favCounter = document.getElementById("favCounter")
    const favCounterCount = document.getElementById("favCounterCount")

    favButton.addEventListener("click", () => {
        // toggle the favourite
        myFavourites.toggleFavourite(listingId, listingTitle, listingUrl)
        // change the background colour of the button
        if (myFavourites.isFavourite(listingId)) {
            favButton.classList.add(`bg-${bgColour}`, "text-white")
        } else {
            favButton.classList.remove(`bg-${bgColour}`, "text-white")
        }
        
        // Show/hide the counter & update the counter
        if (myFavourites.getFavouriteCount() > 0) {
            favCounter.classList.remove("hidden")
            favCounterCount.textContent = myFavourites.getFavouriteCount()
        } else {
            favCounter.classList.add("hidden")
            favCounterCount.textContent = 0
        }
    })
    
    // also apply fav status, counter visibility and update the DOM on page load:
    if (myFavourites.isFavourite(listingId)) {
        favButton.classList.add(`bg-${bgColour}`, "text-white")
    } else {
        favButton.classList.remove(`bg-${bgColour}`, "text-white")
    }
    if (myFavourites.getFavouriteCount() > 0) {
        favCounter.classList.remove("hidden")
        favCounterCount.textContent = myFavourites.getFavouriteCount()
    } else {
        favCounter.classList.add("hidden")
        favCounterCount.textContent = 0
    }
    myFavourites.updateFavouritesDOM()
}

export default myFavourites
