import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var cards = new Swiper('.cards-carousel', {
  autoplay: {
    enabled: true,
    delay: 1000,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

var infoCardsImage = new Swiper('[data-carousel="infoCardsImage"]', {
  direction: 'vertical',
  slidesPerView: 1,
  breakpoints: {
    768: {
      autoplay: {
        enabled: true,
        delay: 3000,
      },
    },
  },
  speed: 1000,
});

var infoCards = new Swiper('[data-carousel="infoCards"]', {
  slidesPerView: 1,
  breakpoints: {
    768: {
      autoplay: {
        enabled: true,
        delay: 3000,
      },
    },
  },
  speed: 1000,
  thumbs: {
    swiper: infoCardsImage,
  },
});

var heroInfo = new Swiper('[data-carousel="heroInfo"]', {
  slidesPerView: 1,
  autoplay: {
    enabled: true,
    delay: 3000,
  },
  speed: 1000,
});

var similarAllotments = new Swiper('[data-carousel="similarAllotments"]', {
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 0,
  navigation: {
    nextEl: '.allotments-next',
    prevEl: '.allotments-prev',
  },
  breakpoints: {
    1280: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 40,
    },
  },
});

var packages = new Swiper('[data-carousel="packages"]', {
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 0,
  navigation: {
    nextEl: '.packages-next',
    prevEl: '.packages-prev',
  },
  breakpoints: {
    1280: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 40,
    },
  },
});

document.querySelectorAll('.imageGallery').forEach(function (gallery) {
  var galleryId = gallery.getAttribute('data-gallery');

  var imageGalleryThumb = new Swiper(
    gallery.querySelector('[data-carousel="imageGalleryThumb"]'),
    {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    }
  );

  var imageGallery = new Swiper(
    gallery.querySelector('[data-carousel="imageGallery"]'),
    {
      spaceBetween: 10,
      autoHeight: true,
      navigation: {
        nextEl: '.imageGallery-next',
        prevEl: '.imageGallery-prev',
      },
      thumbs: {
        swiper: imageGalleryThumb,
      },
    }
  );
});

var imageCarousel = new Swiper('[data-carousel="imageCarousel"]', {
  spaceBetween: 10,
  slidesPerView: 4,
  freeMode: false,
  loop: true,
  navigation: {
    nextEl: '.imageCarousel-next',
    prevEl: '.imageCarousel-prev',
  },
});

var testimonialWidget = new Swiper('[data-carousel="testimonialWidget"]', {
  spaceBetween: 30,
  slidesPerView: 1.2,
  freeMode: false,
  loop: true,
  breakpoints: {
    768: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 30,
    },
    1280: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 40,
    },
  },
});

var socialCarousel = new Swiper('[data-carousel="socialCarousel"]', {
  spaceBetween: 20,
  slidesPerView: 'auto',
  freeMode: false,
  loop: false,
  navigation: {
    nextEl: '.socialCarousel-next',
    prevEl: '.socialCarousel-prev',
  },
});

const latestNewsElement = document.querySelector(
  '[data-carousel="latestNews"]'
);

if (latestNewsElement) {
  var latestNews = new Swiper('[data-carousel="latestNews"]', {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    speed: 700,
    navigation: {
      nextEl: '.news-next',
      prevEl: '.news-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 1,
      },
    },
    on: {
      slideChange: function () {
        updateUpcomingSlides(this);
      },
    },
  });

  function updateUpcomingSlides(swiperInstance) {
    const currentIndex = swiperInstance.realIndex;

    for (let i = 1; i <= 3; i++) {
      const nextSlideIndex = (currentIndex + i) % swiperInstance.slides.length;
      swiperInstance.slides.forEach((slide) =>
        slide.classList.remove(`slowEntry-${i}`)
      );

      if (i === 1) {
        swiperInstance.slides[nextSlideIndex].classList.add('slowEntry-1');
      } else if (i === 2) {
        swiperInstance.slides[nextSlideIndex].classList.add('slowEntry-2');
      } else if (i === 3) {
        swiperInstance.slides[nextSlideIndex].classList.add('slowEntry-3');
      }
    }
  }

  updateUpcomingSlides(latestNews);
}