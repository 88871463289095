const initTracking = () => {

    // GA4 Tracking for iFramed Lanser/Pardot Forms:
    window.addEventListener("message", function (event) {

        const allowedOrigins = [
            "https://go.lanser.com.au"
        ]
        // Security check (ensure the postMessage message comes from a trusted origin)
        if (!allowedOrigins.includes(event.origin)) {
            return
        }
        // Ensure the message has the expected event data
        if (event.data && event.data.event === "iframe_form_submission") {
            const formData = event.data.data;

            // Send GA4 event
            if (window.gtag) {
                window.gtag("event", "iframe_form_submission", {
                    form_id: formData.formId,
                    form_name: formData.formName,
                });
            } else {
                console.warn("GA4 is not loaded");
            }
        }
    });
}
// go
initTracking();