/**
 * Utilities
 */

// import debounce from "lodash/debounce";
const isDev = (process.env.NODE_ENV !== 'production');

const kebabize = (str) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())

// Passes the parent page theme to the Pardot form iframe, when the iframe is loaded
const passPageThemeToPardotForm = () => {
	window.addEventListener("DOMContentLoaded", () => {
        const iframes = document.querySelectorAll("iframe");
        iframes.forEach(iframe => {
            if (iframe.src.includes("go.pardot.com") || iframe.src.includes("go.lanser.com.au")) {
                iframe.addEventListener("load", () => {
                    const theme = document.body.getAttribute("data-site-handle");
                    iframe.contentWindow.postMessage({ theme }, "*");
                });
            }
        });
    });
}

export {
    // debounce,
    isDev,
    kebabize,
    passPageThemeToPardotForm,
};
