/**
 * Animated Stats Counter
 */

/**
 * Insert a hidden SVG containing icons at the top of the body
 */

const counterElements = document.querySelectorAll('.counter')

const initCounters = () => {
	counterElements.forEach(counter => {
		// pattern used to seperate input number from html into an array of numbers and non numbers. EX $65.3M -> ["$65.3M", "$", "65", ".", "3", "M"]
		const patt = /(\D+)?(\d+)(\D+)?(\d+)?(\D+)?/;
		const time = 1000;
		let result = [...patt.exec(counter.textContent.trim())];
		let fresh = true;
		let ticks;
	
		// Remove first full match from result array (we dont need the full match, just the individual match groups).
		if (result.length > 1) {
			result.shift();
		}
		// Remove undefined values from result array where they didnt have a match in one of the optional regex groups
		result = result.filter(res => res != null);

		while (counter.firstChild) {
			counter.removeChild(counter.firstChild);
		}
	
		for (let res of result) {
			if (isNaN(res)) {
				counter.insertAdjacentHTML("beforeend", `<span>${res}</span>`);
			} else {
				for (let i = 0; i < res.length; i++) {
					counter.insertAdjacentHTML(
						"beforeend",
						`<span data-value="${res[i]}">
							<span>&ndash;</span>
							${Array(parseInt(res[i]) + 1)
								.join(0)
								.split(0)
								.map(
									(x, j) => `
								<span>${j}</span>
							`
								)
								.join("")}
						</span>`
					);
				}
			}
		}
	
		ticks = [...counter.querySelectorAll("span[data-value]")];
	
		let activate = () => {
			let top = counter.getBoundingClientRect().top;
			let offset = window.innerHeight * 0.8;
	
			setTimeout(() => {
				fresh = false;
			}, time);
	
			if (top < offset) {
				setTimeout(() => {
					for (let tick of ticks) {
						let dist = parseInt(tick.getAttribute("data-value")) + 1;
						tick.style.transform = `translateY(-${dist * 100}%)`;
					}
				}, fresh ? time : 0);
				window.removeEventListener("scroll", activate);
			}
		};
		window.addEventListener("scroll", activate);
		activate();
	});
}

initCounters()