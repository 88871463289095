import L from "leaflet"
import 'leaflet.gridlayer.googlemutant';
import 'leaflet/dist/leaflet.css'

/**
 * initMap
 *
 * Renders a Leaflet Map (with Google layer) onto the selected element

 *
 * @date    22/10/19
 * @since   5.8.6
 *
 */
const initHybridMap = (el, mapArgs = {}, communityMapLayer = {}) => {

    let zoom = parseInt(el.dataset.zoom) || 16
    let center = (el.dataset.centerLat && el.dataset.centerLng) ? {lat: parseFloat(el.dataset.centerLat), lng: parseFloat(el.dataset.centerLng)} : false
    let scrollWheelZoom = el.dataset.scrollWheelZoom || false
    let zoomSnap = el.dataset.zoomSnap || 0.5
    let showCommunityMap = el.dataset.showCommunityMap === 'true' || false
    
    const communityData = JSON.parse(el.dataset.communityData || '{}')
    let mapId = communityData.mapId || null

    // define the community map layer bounds
    const baseMapLayerBounds = communityData.baseMapLayerBounds ?? []
    const baseMapScaleFactor = communityData.imgScaleFactor ?? 1
    const baseMapImageSrc = communityData.imgSrc ?? ''
    const baseMapWidth = Number(communityData.imgWidth) ?? 1
    const baseMapHeight = Number(communityData.imgHeight) ?? 1
    const baseMapOpacity = communityData.imgOpacity ?? 1

    if (showCommunityMap && baseMapLayerBounds.length < 1) {
        console.error('Whoops! No map layer bounds provided in CMS!')
        return
    }

    // Initialize an array for markers on the map instance
    let markersData = JSON.parse(el.dataset.markers || '[]')
    let hybridMapMarkers = []

    // merge additional community map options into communityMapLayer
    // todo: figure out if we even need this?
    communityMapLayer = {
        ...communityMapLayer,
        ...JSON.parse(el.dataset.communityMapLayer || '{}'),
    }

    // merge additional options into mapArgs
    mapArgs = {
        ...mapArgs,
        ...JSON.parse(el.dataset.additionalOptions || '{}'),
    }

    // merge default map args with any passed in
    mapArgs = {
        zoom: 18, // initial zoom level needs to be 18 for overlay scaling to work (zoom gets reset to dynamic value on init anyway)
        center: center,
        zoomSnap: zoomSnap,
        scrollWheelZoom: scrollWheelZoom,
        ...mapArgs,
    }

    // Remember: Maps created in Google Cloud Console NEED to use the "raster" rendering type, NOT "vector".. 
    // (see the same code in interactive-masterplan.js for more comments)
    const tileLayer = L.gridLayer.googleMutant({ 
        type: 'roadmap',
        version: 'beta',
        mapId: mapId,
    })

    // Instantiate the LeafletJS map
    const map = L.map(el, mapArgs)
    tileLayer.addTo(map)

    // Add markers if they exist
    markersData.forEach((markerSettings) => {
        // if markerSettings is an object and has at least the position property, then it's valid
        if (typeof markerSettings === 'object' && markerSettings.position) {
            // cast each item in the position property to a float
            markerSettings.position = {
                lat: parseFloat(markerSettings.position.lat),
                lng: parseFloat(markerSettings.position.lng),
            }
            const markerInstance = initMarker(markerSettings, map)
            hybridMapMarkers.push(markerInstance)
        }
    })

    // ----- community map image bounding box ----- //
    // (see the same code in interactive-masterplan.js for more comments)
    if (showCommunityMap) {
        let imgBounds = []
        if (baseMapLayerBounds.length === 2) {
            imgBounds = L.latLngBounds([baseMapLayerBounds[0],baseMapLayerBounds[1]])
        } else {
            const originContainerPoint = map.latLngToContainerPoint(baseMapLayerBounds[0], el)
            const destinationContainerPoint = L.point(originContainerPoint.x + (baseMapWidth * baseMapScaleFactor), originContainerPoint.y + (baseMapHeight * baseMapScaleFactor))
            const destinationLatLngCoords = map.containerPointToLatLng(destinationContainerPoint)
            imgBounds = L.latLngBounds([baseMapLayerBounds[0],destinationLatLngCoords])
        }

        // create the community base layer image, add it to the map & re-set the zoom level as defined in the data attribute
        const communityBaseLayer = L.imageOverlay(baseMapImageSrc, imgBounds, {
            opacity: baseMapOpacity,
            zIndex: 1,
        })

        communityBaseLayer.addTo(map)
    }

    const resizeObserver = initResizeObserver(map)
    resizeObserver.observe(el)
    map.invalidateSize() // also run an initial resize

    if (hybridMapMarkers.length > 1) {
        var bounds = calcMarkerBounds(hybridMapMarkers)
        console.info('re-centering map around marker bounds')
        setTimeout(() => {
            map.fitBounds(bounds, {padding: [100, 100]})
        }, 1000)
        // TODO: figure out why we need a setTimeout hack to get the map to fit the marker bounds after the map has been initialized
    } else {
        map.panTo(hybridMapMarkers[0].getLatLng())
    }

    map.setZoom(zoom)
}

// ----- RESIZE OBSERVER ----- //
// Resize observer to handle map resizing
function initResizeObserver(mapInstance) {
    const resizeObserver = new ResizeObserver(() => {
        // console.log('resizing')
        mapInstance.invalidateSize()
    })
    return resizeObserver
}

/**
 * initMarker
 *
 * Creates a marker for the given HTML element and map.
 *
 * @param   object markerSettings The marker settings.
 * @param   object map The map instance.
 * @return  object The marker instance.
 */
const initMarker = (markerSettings, map) => {

    const theSettings = {
        // other default settings go here
        ...markerSettings,
    }

    const markerIcon = L.icon({
        iconUrl: theSettings.icon || null,
        // shadowUrl: 'leaf-shadow.png',
        // shadowSize:   [50, 64], // size of the shadow
        iconSize:     [60, 69], // size of the icon
        iconAnchor:   [30, 69], // point of the icon which will correspond to marker's location
        // shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor:  [0, -69] // point from which the popup should open relative to the iconAnchor
    })

    // Create marker instance on the map.
    let marker = L.marker(theSettings.position, {icon: markerIcon})
    
    if (theSettings.content !== null && theSettings.content !== '') {
        marker.bindPopup(`${theSettings.content}`)
    }

    marker.addTo(map)

    // Link marker to DOM element
    const linkedElement = document.getElementById(markerSettings.linkedElement || '')
    const sharedSelector = markerSettings.sharedSelector || ''

    if (linkedElement) {
        // Event listener for DOM element click
        linkedElement.addEventListener("click", () => {
            // console.log('matching element clicked, zooming map to', markerSettings.zoom)
            map.setZoom(markerSettings.zoom || 18)
            map.panTo(marker.getLatLng())

            // set the info window to the marker
            marker.openPopup()

            document.querySelectorAll(`.${sharedSelector}`).forEach(el => el.classList.remove("active"))
            linkedElement.classList.add("active")
        });
    } else {
        console.warn('No linked element found for marker', markerSettings)
    }

    // Show info window when marker is clicked.
    marker.on('click', function() {
        // move map to marker
        map.setView(markerSettings.position, markerSettings.zoom || 18)

        // Todo: check shared selector functionality still works
        if (sharedSelector.length > 1) {
            // Remove active class from all shared selectors
            const sharedSelectors = document.querySelectorAll(`.${sharedSelector}`)
            sharedSelectors.forEach(el => el.classList.remove("active"));
            if (linkedElement) {
                // fire custom event for Alpine.js to pick up
                window.dispatchEvent(new CustomEvent('markerclicked', { detail: { marker: marker, linkedElement: linkedElement.id } }))
            } else {
                console.warn('Can\'t highlight linked element for marker. linkedElement is not set', markerSettings)
            }
        }

    })

    // Return marker instance
    return marker
}

/**
 * calcMarkerBounds
 *
 * Calculates the bounds of all markers on the map, so it can be used to center the map.
 *
 * @param   array markers The markers to center the map on.
 * @return  void
 */
const calcMarkerBounds = (markers = []) => {
    // Create map boundaries from all map markers.
    var markerPositions = [...markers.map(marker => marker.getLatLng())]
    const bounds = new L.LatLngBounds(markerPositions)
    
    return bounds
}

// GO Time for all hybrid maps on the page
const mapEls = document.querySelectorAll('[data-map-type="hybrid"]')

mapEls.forEach((el) => {
    const options = {}
    initHybridMap(el, options)
})