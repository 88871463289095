/**
 * Lot Property Search
 *
 * Handles additional lot/property search functionality
 * on the "Now Selling" page
 *
 */

const nowSellingFormEls = document.querySelectorAll('.nowSellingExtraForm');
const initNowSellingFilterSort = (form) => {
  const filterElements = document.querySelectorAll('.lotPropertyFilter');
  const sortElements = document.querySelectorAll('.lotPropertySort');

  filterElements.forEach((filterElement) => {
    if (filterElement) {
      filterElement.addEventListener('change', (e) => {
        const input = form.querySelector('select[name="filter"]');

        if (input) {
          input.value = e.target.value;
          form.submit();
        } else {
          console.warn('Filter input not found!');
        }
      });
    }
  });
  sortElements.forEach((sortElement) => {
    if (sortElement) {
      sortElement.addEventListener('change', (e) => {
        const input = form.querySelector('select[name="sort"]');

        if (input) {
          input.value = e.target.value;
          form.submit();
        } else {
          console.warn('Sort input not found!');
        }
      });
    }
  });
};

nowSellingFormEls.forEach((nowSellingFormEl) => {
  if (nowSellingFormEl) {
    initNowSellingFilterSort(nowSellingFormEl);
  }
});